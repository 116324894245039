import React from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { createState, useState } from '@hookstate/core'
import { CookieSettings, getCookieSettings, updateCookies } from 'utils/cookies'
import { Link } from 'gatsby'
import MaterialLink from '@material-ui/core/Link'

type CookieBannerProps = {
  isFixed?: boolean
}

const cookiesSettings = createState<CookieSettings>({
  analytics: false,
  necessary: true,
})
/** isFixed - If true the banner is displayed at the bottom-right corner of the page only if cookies have not been accepted yet */
const CookieBanner: React.FC<CookieBannerProps> = ({ isFixed }) => {
  const cookies = useState(cookiesSettings)
  const show = useState(true)
  const cookiesAccepted = !!getCookieSettings()
  return (
    (((!cookiesAccepted && show.get()) || !isFixed) && (
      <div
        className={`${
          isFixed ? 'fixed' : 'block'
        } bottom-0 right-0 left-0 sm:bottom-2 sm:right-4 sm:left-auto sm:max-w-sm z-50`}
      >
        <Card style={{ backgroundColor: '#1e3445', color: 'white' }}>
          <CardContent>
            <Typography color="inherit" gutterBottom>
              🍪 {isFixed ? 'Cookie Notice' : 'Cookie settings'}
            </Typography>
            {isFixed && (
              <Typography variant="body2" component="p">
                We use cookies to learn how you interact with our content, and
                show you relevant content based on your browsing history. You
                can adjust your settings below. Here’s our{' '}
                <MaterialLink component={Link} to="/policy">
                  policy
                </MaterialLink>
              </Typography>
            )}
            <FormControl component="fieldset" className="svg-white">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox name="gilad" color="primary" />}
                  label="Strictly Necessary"
                  onChange={() => cookies.necessary.set(b => !b)}
                  checked={cookies.necessary.get()}
                />
                <FormControlLabel
                  control={<Checkbox name="jason" color="primary" />}
                  label="Analytics"
                  onChange={() => cookies.analytics.set(b => !b)}
                  checked={cookies.analytics.get()}
                />
              </FormGroup>
            </FormControl>
          </CardContent>
          {isFixed && (
            <CardActions className="justify-end">
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  updateCookies(cookies.get())
                  show.set(false)
                }}
              >
                Save & Close
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  const acceptAll = { necessary: true, analytics: true }
                  updateCookies(acceptAll)
                  cookies.set(acceptAll)
                  show.set(false)
                }}
              >
                Accept All
              </Button>
            </CardActions>
          )}
        </Card>
      </div>
    )) ||
    null
  )
}

export default CookieBanner
