'use strict'
exports.__esModule = true
exports.breakpointPixelValues = exports.breakpointValues = void 0
exports.breakpointValues = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}
exports.breakpointPixelValues = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
}
