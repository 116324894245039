/* eslint-disable import/no-named-as-default-member */
import firebase from 'firebase/app'
import 'firebase/analytics'

export type CookieSettings = {
  analytics: boolean
  necessary: boolean
}

export const getCookieSettings = () => {
  if (typeof window !== 'undefined') {
    const cookieSettingsJson = localStorage.getItem('cookie-settings')
    const cookieSettings =
      (cookieSettingsJson &&
        (JSON.parse(cookieSettingsJson) as CookieSettings)) ||
      null
    return cookieSettings
  }
  return null
}

export const updateCookies = (settings?: CookieSettings) => {
  if (typeof window !== 'undefined') {
    if (settings) {
      if (settings.necessary) {
        localStorage.setItem('cookie-settings', JSON.stringify(settings))
      } else {
        localStorage.removeItem('cookie-settings')
      }
    }

    if (getCookieSettings()?.analytics) {
      firebase.analytics()
    }
  }
}
