module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"phase2","short_name":"phase2","start_url":"/","background_color":"#051D30","theme_color":"#00D0CA","display":"standalone","lang":"en","description":"phase2 are dedicated to enabling innovative and aspiring startups access to the most talented individuals to scale their teams, establish their culture and thrive","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5014a128bfd6d6147ea2cc55df40744b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
