'use strict'
exports.__esModule = true
exports.gradients = exports.colors = void 0
exports.colors = {
  primary: {
    light: '#64FFFD',
    DEFAULT: '#00D0CA',
    dark: '#029A9B',
    darker: '#017374',
    darkest: '#062F40',
  },
  secondary: {
    DEFAULT: '#23435C',
    darker: '#182E3F',
    darkest: '#051D30',
  },
  red: '#F60000',
  white: '#FFFFFF',
  grey: {
    DEFAULT: '#D8D8D8',
    dark: '#A9B1B7',
    darker: '#5D676F',
  },
  black: '#000000',
  transparent: 'transparent',
}
exports.gradients = {
  primary:
    'linear-gradient(358.85deg, rgba(100, 255, 253, 0.45) -19.44%, rgba(0, 208, 202, 0.9) 99.17%)',
  secondary:
    'linear-gradient(358.85deg, rgba(0, 255, 248, 0.075) -19.44%, rgba(4, 208, 202, 0.15) 99.17%)',
  dark: 'linear-gradient(358.85deg, #19444D -19.44%, #143443 99.17%)',
}
