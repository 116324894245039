/* eslint-disable @typescript-eslint/no-var-requires */
const React = require('react')
const { colors } = require('./src/styles/colors')
const { breakpointValues } = require('./src/styles/breakpoints')

const { createTheme, ThemeProvider } = require('@material-ui/core/styles')
require('./src/styles/global.css')

if (typeof window !== 'undefined') {
  window.process = undefined
}

const theme = createTheme({
  palette: {
    type: 'dark',
    breakpoints: {
      values: breakpointValues,
    },
    primary: {
      light: colors.primary.light,
      main: colors.primary.DEFAULT,
      dark: colors.primary.DARK,
    },
    secondary: {
      main: colors.secondary.DEFAULT,
    },
    error: {
      main: colors.red,
    },
  },
  overrides: {
    MuiPopover: {
      paper: {
        backgroundColor: colors.secondary.darker,
      },
    },
  },
})

const CookieBanner = require('./src/components/CookieBanner').default
// Wraps every page in a component
exports.wrapPageElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      {element}
      <CookieBanner isFixed />
    </ThemeProvider>
  )
}
